import 'bootstrap/js/dist/collapse';
import 'whatwg-fetch'
import 'finally-polyfill'
import $ from 'jquery/dist/jquery.slim';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.pl';

window.jQuery = $; // For compatibility with bootstrap's Collapse events

// ensure proper kv placement regardless of form height
function checkHeight() {
    if (($(window).width() < 767)) {
        if (($(".main-content").height() > 470)) {
            $(".kv-container").css({
                "top": "0",
                "bottom": "auto",
            })
        }
    } else if (($(window).width() > 767) && ($(window).width() < 1200)){
        if (($(".main-content").height() > 508)) {
            $(".kv-container").css({
                "top": "auto",
                "bottom": "-1px",
            })
        } else {
            $(".kv-container").css({
                "top": "95px",
                "bottom": "auto",
            })
        }
    }  else if ($(window).width() > 1200) {
        if (($(".main-content").height() > 594)) {
            $(".kv-container").css({
                "top": "auto",
                "bottom": "-1px",
            })
        } else {
            $(".kv-container").css({
                "top": "0",
                "bottom": "auto",
            })
        }
    }
}

// collapse elements control
const collapsedClass = 'collapsed';
const expandedClass = 'expanded';
let agreement1Text = $('#agreement1Text');
agreement1Text.on('hidden.bs.collapse', function () {
    $("a.agreement1Append").html('<u>rozwiń</u>');
    $(this).parent().removeClass(expandedClass).addClass(collapsedClass);
    $(this).parent().find('.ellipsis').text(" ...");
    checkHeight()
})
agreement1Text.on('hide.bs.collapse', function () {
    checkHeight()
})
agreement1Text.on('show.bs.collapse', function () {
    $("a.agreement1Append").html('<u>zwiń</u>');
    $(this).parent().removeClass(collapsedClass).addClass(expandedClass);
    $(this).parent().find('.ellipsis').text(",");
    checkHeight()
})
agreement1Text.on('shown.bs.collapse', function () {
    checkHeight()
})

let agreement2Text = $('#agreement2Text')
agreement2Text.on('hidden.bs.collapse', function () {
    $("a.agreement2Append").html('<u>rozwiń</u>');
    $(this).parent().removeClass(expandedClass).addClass(collapsedClass);
    $(this).parent().find('.ellipsis').text(" ...");
    checkHeight()
})
agreement2Text.on('hide.bs.collapse', function () {
    checkHeight()
})
agreement2Text.on('show.bs.collapse', function () {
    $("a.agreement2Append").html('<u>zwiń</u>');
    $(this).parent().removeClass(collapsedClass).addClass(expandedClass);
    $(this).parent().find('.ellipsis').text(",");
    checkHeight();
})
agreement2Text.on('shown.bs.collapse', function () {
    checkHeight()
})

const phonePrefix = '+48';

// phone input formating
var cleavePhone = new Cleave('#inputPhoneNumber', {
    phone: true,
    phoneRegionCode: 'pl',
    prefix: phonePrefix,
});

// form validation
const forms = document.querySelectorAll('.needs-validation')
let inputPhoneIcon = $(".input-phone-icon");
let inputNameIcon = $(".input-name-icon");
let formFailIcon = $(".send-result-container-fail .result-decoration-img");
let formSuccessIcon = $(".send-result-container .result-decoration-img");
let patternPhone = /^\+(?:48)\s?(?:(?:450)|(?:5[0137][0-9])|(?:6[069][0-9])|(?:7[2389][0-9])|(?:88[0-9])|(?:000))\s?(?:[0-9]{3})\s?(?:[0-9]{3})$/;
let patternName = /^[A-Z-a-ząóęćłśńżźĄÓĘĆŁŚŃŻŹ\s-]{1,120}$/i;

function validateName(name) {
  if (name.length === 0) {
    return validateName.REQUIRED;
  } else if (!patternName.test(name)) {
    return validateName.INVALID;
  }
  return null;
}
validateName.REQUIRED = 'required';
validateName.INVALID = 'invalid';

function validatePhone(phone) {
  if (phone.length === 0 || phone === phonePrefix) {
    return validatePhone.REQUIRED;
  } else if (
    phone.length !== 12
    || !patternPhone.test(phone)
  ) {
    return validatePhone.INVALID;
  }
  return null;
}
validatePhone.REQUIRED = 'required';
validatePhone.INVALID = 'invalid';

function renderTextInputValidation(formGroupEl, violation) {
  const formControlEl = formGroupEl.find('.form-control');
  const iconEl = formGroupEl.find('.input-icon');
  const isInvalid = !!violation;

  formGroupEl.find('.feedback').hide();
  formControlEl[0].setCustomValidity(violation || '');
  formControlEl.toggleClass('is-invalid', isInvalid);

  if (isInvalid) {
    formGroupEl.find('.feedback--' + violation).css('display', 'block');
    iconEl.css('display', 'block');
  } else {
    iconEl.hide();
  }
}

function loadErrorIcon() {
    if (!inputNameIcon.hasClass("loading")) {
        inputNameIcon.attr('src', inputNameIcon.attr('data-src') );
        inputNameIcon.addClass("loading");
    }
    if (!inputPhoneIcon.hasClass("loading")) {
        inputPhoneIcon.attr('src', inputPhoneIcon.attr('data-src') )
        inputPhoneIcon.addClass("loading");
    }
}

function loadFormFailIcon() {
    formFailIcon.each( function() {
        if (!$(this).hasClass("loading")) {
            $(this).attr('src', $(this).attr('data-src') );
            $(this).addClass("loading");
        }
    })
}

function loadFormSuccessIcon() {
    formSuccessIcon.each( function() {
        if (!$(this).hasClass("loading")) {
            $(this).attr('src', $(this).attr('data-src') );
            $(this).addClass("loading");
        }
    })
}

$("#inputPhoneNumber").on('focus', function () {
    $("#inputPhoneNumberContainer").addClass('focused');
})

$("#inputPhoneNumber").on('input', function () {
    $("#inputPhoneNumberContainer").addClass('inputting');
    if ($(".form-main").hasClass("was-validated")) {
        renderTextInputValidation(
            $(this).parents('.form-group'),
            validatePhone(cleavePhone.getRawValue())
        );
    }
    checkHeight()
})

$("#inputPhoneNumber").on('blur', function () {
    $("#inputPhoneNumberContainer").removeClass('focused');
    if ( ($("#inputPhoneNumber").val().trim() === '+48' ) ) {
        $("#inputPhoneNumberContainer").removeClass('inputting');
        $("#inputPhoneNumberContainer").removeClass('notEmpty');
    }
})

$("#inputPhoneNumber").on('change', function () {
    if ($("#inputPhoneNumber").val() != 0) {
        $("#inputPhoneNumberContainer").addClass('notEmpty');
    } else {
        $("#inputPhoneNumberContainer").removeClass('notEmpty');
    }
})

$("#inputPhoneNumberContainer .fakePlaceholder").on('click', function () {
    $("#inputPhoneNumber").focus();
})

$("#inputName").on('focus', function () {
    $("#inputNameContainer").addClass('focused');
    setTimeout(() => {
        $(this).attr('placeholder', 'Wpisz imię');
    }, 100);
})

$("#inputName").on('blur', function () {
    $("#inputNameContainer").removeClass('focused');
    $("#inputName").attr('placeholder', '');
})

$("#inputName").on("keyup", function () {
    if ($(".form-main").hasClass("was-validated")) {
        renderTextInputValidation(
          $(this).parents('.form-group'),
          validateName($(this).val())
        );
    }
    checkHeight()
})

$("#inputName").on('change', function () {
    if ($("#inputName").val() != 0) {
        $("#inputNameContainer").addClass('notEmpty');
    } else {
        $("#inputNameContainer").removeClass('notEmpty');
    }
})

$("#inputNameContainer .fakePlaceholder").on('click', function () {
    $("#inputName").focus();
})

$("input").on("change", function () {
    checkHeight();
})

$(".agreementAppend").on("click", function () {
    checkHeight();
})

$(window).on('resize', function () {
    checkHeight();
})

let lockButton = false;

if ($(".form-check-input").length) {
    $(".input-check").on("click", function () {
        if ($(this).hasClass("selected")) {
            $(this).removeClass("selected");
            $(this).addClass("deselected");
            $(this).addClass("is-invalid");
            if ($("#checkboxAgreement1").hasClass('is-invalid')) {
                $(".invalid-check1").css("display", "block");
            }
            if ($("#checkboxAgreement2").hasClass('is-invalid')) {
                $(".invalid-check2").css("display", "block");
            }
        } else {
            $(this).addClass("selected");
            $(this).removeClass("deselected");
            $(this).removeClass("is-invalid");
            if (!$("#checkboxAgreement1").hasClass('is-invalid')) {
                $(".invalid-check1").css("display", "none");
            }
            if (!$("#checkboxAgreement2").hasClass('is-invalid')) {
                $(".invalid-check2").css("display", "none");
            }
        }
        if ($(".form-main").hasClass("was-validated")) {
            let marketing1 = $("#checkboxAgreement1").hasClass("selected");
            let marketing2 = $("#checkboxAgreement2").hasClass("selected");
            if (marketing1 === false) {
                $(".invalid-check1").css("display", "block");
                $("#checkboxAgreement1").addClass("is-invalid");
            } else {
                $(".invalid-check1").css("display", "none");
                $("#checkboxAgreement1").removeClass("is-invalid");
            }
            if (marketing2 === false) {
                $(".invalid-check2").css("display", "block");
                $("#checkboxAgreement2").addClass("is-invalid");
            } else {
                $(".invalid-check2").css("display", "none");
                $("#checkboxAgreement2").removeClass("is-invalid");
            }
        }
        checkHeight();
    });
}

function loadFullDataOfIndividualForm() {
    $(".form-main").addClass("was-validated");

    const nameEl = $("input[name='name']");
    const phoneEl = $("input[name='phone']");

    let marketing1 = $("#checkboxAgreement1").hasClass("selected");
    let marketing2 = $("#checkboxAgreement2").hasClass("selected");

    const nameViolation = validateName(nameEl.val());
    const phoneViolation = validatePhone(cleavePhone.getRawValue());

    const consentsViolation = !marketing1 || !marketing2;

    renderTextInputValidation(nameEl.parents('.form-group'), nameViolation);
    renderTextInputValidation(phoneEl.parents('.form-group'), phoneViolation);

    if (marketing1 === false) {
        $(".invalid-check1").css("display", "block");
        $("#checkboxAgreement1").addClass("is-invalid");
    } else {
        $(".invalid-check1").css("display", "none");
        $("#checkboxAgreement1").removeClass("is-invalid");
    }
    if (marketing2 === false) {
        $(".invalid-check2").css("display", "block");
        $("#checkboxAgreement2").addClass("is-invalid");
    } else {
        $(".invalid-check2").css("display", "none");
        $("#checkboxAgreement2").removeClass("is-invalid");
    }
    checkHeight();

    if (!nameViolation && !phoneViolation && !consentsViolation) {
        return {
            firstname: nameEl.val(),
            phone: cleavePhone.getRawValue(),
            dataProcessingConsent: marketing1,
            marketingConsent: marketing2,
        }
    } else {
        loadErrorIcon();
        return null;
    }
}

function clearForm() {
    $(".form-check-input.selected").removeClass('selected');
    $("input[name='name']").val('');
    $("input[name='phone']").val('');
}

const formAttrs = (function ($formEl) {
  return [
    'method',
    'action'
  ].reduce(function (out, attrName) {
    const value = $formEl.attr(attrName);
    $formEl.removeAttr(attrName);
    return Object.assign(out, {[attrName]: value});
  }, {});
})($(".form-main"));

// send form
$("#btn-submit-form").on("click", function () {
    if (lockButton) return;
    lockButton = true;
    let data = loadFullDataOfIndividualForm();
    checkHeight();
    if (data != null) {
        const originalText = $("#btn-submit-form").text();
        $("#btn-submit-form")
          .addClass("disabled")
          .attr("aria-disabled", "true")
          .text($("#btn-submit-form").attr("data-loading-text"));

        fetch(formAttrs.action, {
          method: formAttrs.method,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        })
          .then(function (res) {
            const json = res.json();

            return res.status >= 200 && res.status < 300 ?
              json :
              json.then(function (error) {throw error;});
          })
          .then(function (outcome) {
            $(".form-inner").css("display", "none");
            $(".form-decoration-container").css("display", "none");
            $(".send-result-container").css("display", "flex");
            loadFormSuccessIcon();
            clearForm();
            dataLayer.push({
              event: 'landing.giro.form.success',
              ['landing.giro.leadId']: outcome.id
            });
          })
          .catch(function () {
            $(".form-inner").css("display", "none");
            $(".form-decoration-container").css("display", "none");
            $(".send-result-container-fail").css("display", "flex");
            loadFormFailIcon();
          })
          .finally(function () {
            lockButton = false;
            $("#btn-submit-form")
              .removeClass("disabled")
              .attr("aria-disabled", false)
              .text(originalText);
          });
    } else {
        lockButton = false;
    }
});
